* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html,
#root {
  position: relative;
  min-height: 100vh;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

/*
  Notification
 */
.notification-container-top-right {
  margin-top: 62px;
  margin-right: 20px;
  width: auto;
  .n-parent {
    min-width: 350px !important;
  }
}
.notification-item .notification-content {
  padding: 15px !important;
  max-width: 650px;
  white-space: pre-line;
  max-height: 300px;
  overflow-y: auto;
  cursor: default;
}

.notification-content::-webkit-scrollbar-track {
  background: transparent !important;
}
.notification-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3) !important;
  border-radius: 2px;
}
.notification-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5) !important;
}
.notification-content::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
}
.notification-item .notification-close::after {
  font-size: 20px !important;
}

.notification-item .notification-close {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
  transform: translate(5px, -5px);
  background-color: transparent;
}

.notification-message .copy-message {
  position: absolute;
  top: 9px;
  right: 30px;
}

.show-on-hover {
  opacity: 0 !important;
}
.MuiInputBase-root:hover .show-on-hover {
  opacity: 1 !important;
}

[class^='MuiInputBase-root']:hover .show-on-hover {
  opacity: 1 !important;
}

/*
  Scroll
*/

.fullWidth {
  width: 100%;
}

.fullwidth {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.select-none {
  user-select: none !important;
  pointer-events: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input[placeholder] {
  text-overflow: ellipsis;
}
input::-moz-placeholder {
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  text-overflow: ellipsis;
}
input:-ms-input-placeholder {
  text-overflow: ellipsis;
}

.rt-table div {
  min-width: initial !important;
}

.rt-td,
.rt-th {
  width: 0 !important;
}

/*[class*="legendNotched"] {
  max-width: 0.01px !important;
}*/

/**
	Подсказки для Js style
 */
.text-primary,
.text-secondary,
.color-primary,
.color-secondary,
.text-success,
.bg-color,
.bg-primary,
.bg-secondary,
.border-primary,
.border-secondary,
.border,
.border-top,
.border-radius,
.card {
}
